/* 型別 */
import { NIL } from 'uuid';
import type AuxiliaryLineSet from './AuxiliaryLineSet';
import type GameResultSet from './GameResultSet';
import type ShuffleDeskSet from './ShuffleDeskSet';
import type TabletHtmlSet from './TabletHtmlSet';
import type ShuffleBoxSet from './ShuffleBoxSet';

export default class DeskSet {
  Id? = NIL; // FIXME: 如果要改這個的定義請檢查所有使用到的條件判斷式。

  Name = '';

  Code = '';

  Status = true;

  Shoe = 0;

  Round = 0;

  MaxRoundPerShoe = 0;

  Countdown = 0;

  CountdownLength: null | number = null;

  MemberId = NIL;

  LocationId = NIL;

  TabletHtmlId = NIL;

  IsDelete = false;

  Seq = 0;

  GameTypeCode = '';

  GameStatusCode = '';

  PauseStatusCode = '0';

  SettlingTypeCode = '1';

  GameModeCode = '';

  IsReverse = false;

  CreateDatetime = new Date();

  UpdateDatetime = new Date();

  IsLogin = true;

  ErrorExclusionId? = NIL;

  StandardRoundPerShoe: null | number = null;

  TabletHtml = {} as TabletHtmlSet;

  ShoeId = NIL;

  AuxiliaryLineSets: AuxiliaryLineSet[] = [];

  GameResultSets: GameResultSet[] = [];

  GameResultSecondSets: GameResultSet[] = [];

  WarningStatusCode = '0';

  IsLastRound = false;

  IsMaintenance = false;

  IsFixedMaxRound = true;

  CountdownEndTime = new Date();

  MemberNickname = '';

  TabletHtmlmode = '0';

  ShuffleRoomLocationId: string | null = null;

  ShuffleBoxCode = '';

  ShuffleDeskSet: ShuffleDeskSet | null = null;

  ShuffleBox: ShuffleBoxSet | null = null;

  GameResultHistorySets: GameResultSet[] = [];

  GameResultSecondHistorySets: GameResultSet[] = [];

  IsNeedSideShuffle = true;

  SideDeskStatusCode = '';

  ShuffleBoxId = '';

  DonateTemplateTypeCode = '';
}
